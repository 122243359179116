import React from 'react'

//	L O C A L I Z A T I O N

import { locale___Com404Component } from './com-404.locale';

//	S T Y L E

import './com-404.scss'

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class Com404Component extends React.Component<{}, {}> {

//#region 																							R E N D E R

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode {
		return <>
			<div className="com-404---container">
			
				<img src="/assets/404.svg" alt="404" />
			
				<h3>{ this._LOCALE.translate(locale___Com404Component.c404___title) }</h3>
				<p>{ this._LOCALE.translate(locale___Com404Component.c404___message) }</p>
				<span>{ this._LOCALE.translate(locale___Com404Component.c404___footer) }</span>

				<br />
				<br />
			
				<a href={lib_names.endpoints.nearby} target="_blank">
					<span>{ this._LOCALE.translate(locale___Com404Component.c404___gotonearby) }</span>
					<img src="/assets/nearby.svg" alt="Nearby" />
				</a>

			</div>
		</>;
	}

//#endregion

}