import React from 'react';

//	S T Y L E S

import './App.theme.scss';

//	L O C A L E

import { locale___App } from './App.locale';

//	T Y P E S

import { type___api___response } from './types/type.api-response';

//	P R O P S

import { props___App, state___App, state___App___default } from './App.state';

//	L I B S

import { lib_names } from './libs/lib.names';

//	F U N C S

import { funcs_datetime } from './funcs/funcs.datetime';

//	S E R V I C E S

import { service_LocalizationService } from './services/service-localization';
import { service_RestApiService } from './services/service-api';

//	C O M P O N E N T S

import Com404Component from './components/com-404/com-404';
import ComButtonComponent from './components/com-button/com-button';
import ComSpinnerComponent from './components/com-spinners/com-spinner/com-spinner';

//	C L A S S

export default class App extends React.Component<props___App, state___App> {

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___App,
	) {
		super(props);
		this.state = state___App___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___ticket = async () : Promise<void> => {
		const ___temp___event_props: type___api___response = await this._API.tickets___read_single(this.state.ticket___code!);
		this.setState({
			ticket___props: (___temp___event_props.response === 'success' && ___temp___event_props.data !== null) ? ___temp___event_props.data : null,
			ticket___props___isLoading: false,
		});
	}

//#endregion

//#region 																							H A N D L E R S

//	private handle___user_verification___verify = async () => { }

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount() : void {
		setTimeout(() => {
			const ___url: string[] = window.location.toString().replace(/\/$/, '').split('/');
			const ___url___last_param: string = ___url[___url.length - 1];
			const ___url___ticket_code: string | null = (___url___last_param) ? ___url___last_param : null;
			this.setState({
				ticket___code: ___url___ticket_code!,
				ticket___props___isLoading: true
			}, async () => {
				await this.read_props___ticket();
			});
		}, 50);
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode {
		return <>
			{(this.state.ticket___props___isLoading === true ) ? <>
				<ComSpinnerComponent />
			</> : <>
				{(this.state.ticket___props === null) ? <>
					<Com404Component />
				</> : <>

{/**
 *  
 * 
 *									T I C K E T S   V I E W
 * 
 * 
 */}

					<div className="ticket---container---outer">
						<div className="ticket---container">
							
							<div className="ticket---container---upper">
								<img src="./assets/logo.horizontal.svg" />
								<h3>{ this.state.ticket___props['ticket_event']['event_name'] }</h3>
								<h4>{ this._LOCALE.translate(locale___App.placeholder___event_datetime, [this.funcs___datetime.datetime___get___date(this.state.ticket___props['ticket_event']['event_datetime_begin']), this.funcs___datetime.datetime___get___time(this.state.ticket___props['ticket_event']['event_datetime_begin'])]) }</h4>
							</div>

							<div className="ticket---container---lower">
								<div className="ticket---container---user-data">
									<p className="c-printed-font">
										{(this.state.ticket___props['ticket_type'] && this.state.ticket___props['ticket_type']['ticket_type_name']) ? <>
											{ this.state.ticket___props['ticket_type']['ticket_type_name'] }
										</> : <>
											{ this._LOCALE.translate(locale___App.placeholder___ticket_type_name___invite) }
										</>}
									</p>
									<p>{ this._LOCALE.translate(locale___App.placeholder___ticket_type_name___placeholder) }</p>
								</div>
								<div className="ticket---container---user-data">
									<p className="c-printed-font">{ this.state.ticket___props['ticket_person_name'] }</p>
									<p>{ this._LOCALE.translate(locale___App.placeholder___ticket_person_name___placeholder) }</p>
								</div>
								<div className="ticket---container---code">
									<img src={ lib_names.endpoints.qrs_generator + this.state.ticket___props['ticket_code'] }/>
									<h6 className="c-printed-font">{ this.state.ticket___props['ticket_code'] }</h6>
								</div>
							</div>

							<br />

							<a id="btn---download---ticket" style={{display:'none'}} href={ this.state.ticket___props['ticket_download'] } target="_blank" download={true}>ticket_download</a>
							<ComButtonComponent icon="fas fa-cloud-download-alt" text={this._LOCALE.translate(locale___App.button___download)} event___onClick={() => { document.getElementById('btn---download---ticket')?.click() }}/>

							<br />

							<p className="ticket---container---copyright">
								<span>{this._LOCALE.translate(locale___App.no_cookies_here)}</span>
								<span> • </span>
								<a href="https://www.nopr.me/" target="_blank">NoPR.me</a>
							</p>

						</div>
						<img className="ticket---container---bg" src={ this.state.ticket___props['ticket_event']['event_poster'] } />
					</div>

{/**
 * 
 * 
 *									O T P   V E R I F I C A T I O N
 * 
 * 
 */}

					{/*
						{(this.state.checkout_step === 'verification' && this.state.identity___verication_step === 'requested') ? <>
							<MdVerificationOtpModal phone_number={this.state.ticket___buyer.contacts.phone} event___onOTPValidation={() => { this.handle___user_verification___verify(); }}/>
						</> : <></>}
					*/}

				</>}
			</>}
		</>;
	}

//#endregion

}