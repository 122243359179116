import React from 'react';

//	S T Y L E

import './com-button.style.scss';

//	S T A T E S   -   P R O P S

import { props___ComButtonComponent } from './com-button.state';

//	C L A S S

export default class ComButtonComponent extends React.Component<props___ComButtonComponent, {}> {

//#region 																							R E N D E R

	render() : React.ReactNode {
		return <>
			<button className="com-button---component" onClick={() => { this.props.event___onClick(); }}>
				{(this.props.icon && this.props.icon !== null) ? <>
					<i className={ this.props.icon }></i>
				</> : <></>}
				<h4>{ this.props.text }</h4>
			</button>
		</>;
	}

//#endregion

}