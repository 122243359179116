import React from 'react'

//	S T Y L E

import './com-spinner.scss'

//	S T A T E S   -   P R O P S

import { props___ComSpinnerComponent } from './com-spinner.state';

//	C L A S S

export default class ComSpinnerComponent extends React.Component<props___ComSpinnerComponent, {}> {

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComSpinnerComponent,
	) {
		super(props);
	}

//#endregion

//#region 																							R E N D E R

	render(): React.ReactNode {
		return <>
			<div className={ 'com-spinner-component---' + ((this.props.size) ? this.props.size : 'standard' ) }>
				<img className="com-spinner-component---inner" src="/assets/spinner/inner.svg"/>
				<img className="com-spinner-component---outer" src="/assets/spinner/outer.svg"/>
			</div>
		</>;
	}

//#endregion

}