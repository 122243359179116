//	P R O P S

export type props___App = {

}

//	S T A T E

export interface state___App {

	ticket___code: string | null,
	ticket___props: any | null,
	ticket___props___isLoading: boolean,

}

//	S T A T E   D E F A U L T S

export const state___App___default: state___App = {

	ticket___code: null,
	ticket___props: null,
	ticket___props___isLoading: true,

}