//	L I B S

import { lib_names } from '../libs/lib.names';

//	T Y P E S

import { type___api___response } from '../types/type.api-response';

//	E X P O R T

export class service_RestApiService {

//#region 																							D E C L A R A T I O N S

	private _HEADERS: any = { 'Content-Type': 'application/json' };

//#endregion

//#region 																							R E T U R N   S E R V E R   R E S P O N S E

	private async return_server_response(___target: string, ___to_post: any | {} = {}) : Promise<any> {
		const ___s_route: string = lib_names.endpoints.server_api + 'checkout_ticketview' + '/' + ___target;
		try {
			const ___s_response: Response = await fetch(___s_route, { method: 'POST', headers: this._HEADERS, body: JSON.stringify(___to_post) });
			if (___s_response.ok) {
				try {
					const ___server_response: any = await ___s_response.json();
					return (___server_response !== null && ___server_response !== undefined) ? ___server_response : null;
				} catch (___e: any) {
					return null;
				}
			} else {
				return null;
			}
		} catch (___e_main: any) {
			console.error('Error fetching server response:', ___e_main);
			return null;
		}
	}

//#endregion

//#region 																							T I C K E T S

	async tickets___read_single(___ticket_code: string) : Promise<type___api___response> {
		const ___to_post: any = { 'ticket_code': ___ticket_code };
		return await this.return_server_response('tickets/read_single', ___to_post)
	}

//#endregion

//#region 																							V E R I F I C A T I O N

	async verification___otp___send(___phone_number: string) : Promise<type___api___response> {
		const ___to_post: any = { 'phone_number': ___phone_number };
		return await this.return_server_response('verification/otp_send', ___to_post)
	}

	async verification___otp___verify(___phone_number: string, ____otp_code: string) : Promise<type___api___response> {
		const ___to_post: any = { 'phone_number': ___phone_number, 'otp_code': ____otp_code };
		return await this.return_server_response('verification/otp_verify', ___to_post)
	}

//#endregion

}