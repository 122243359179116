export const locale___App = {
	'placeholder___event_datetime': {
		de_de: "Beginnt am {{1}} um {{2}}",
		en_us: "Starts on {{1}} at {{2}}",
		es_es: "Empieza el {{1}} a las {{2}}",
		fr_fr: "Commence le {{1}} à {{2}}",
		it_it: "Inizia il {{1}} alle {{2}}"
	},
	'placeholder___ticket_type_name___invite': {
		de_de: "Einladung zur Liste",
		en_us: "Invite to List",
		es_es: "Invitación a la Lista",
		fr_fr: "Invitation sur la Liste",
		it_it: "Invito in Lista"
	},
	'placeholder___ticket_type_name___placeholder': {
		de_de: "Ticketkategorie",
		en_us: "Ticket category",
		es_es: "Categoría de entrada",
		fr_fr: "Catégorie de billet",
		it_it: "categoria ticket"
	},
	'placeholder___ticket_person_name___placeholder': {
		de_de: "Vorname Nachname",
		en_us: "First name Last name",
		es_es: "Nombre Apellido",
		fr_fr: "Prénom Nom",
		it_it: "nome cognome"
	},
	'button___download': {
		de_de: "Herunterladen",
		en_us: "Download",
		es_es: "Descargar",
		fr_fr: "Télécharger",
		it_it: "Scarica"
	},
	'no_cookies_here': {
		de_de: "Diese Seite speichert keine Cookies",
		en_us: "This page does not store cookies",
		es_es: "Esta página no registra cookies",
		fr_fr: "Cette page n'enregistre pas de cookies",
		it_it: "Questa pagina non registra cookies"
	},
};